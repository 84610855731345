import axios, { AxiosResponse } from "axios";

const uri = process.env.REACT_APP_PORTAL_URI;
const apiUsername = process.env.REACT_APP_API_USERNAME || "";
const apiPassword = process.env.REACT_APP_API_PASSWORD || "";

const auth = {
  username: apiUsername,
  password: apiPassword,
};

export const getPropertyDetails = async (
  propertyId: string,
  portfolioId: string
) => {
  const url = `${uri}/property/`;
  const params = {
    propertyId,
    portfolioId,
  };
  try {
    const result = await axios.get(url, { params, auth });
    return result.data;
  } catch (error: any) {
    console.error(JSON.stringify(error));
    throw Error(error.message);
  }
};
