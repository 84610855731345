import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export const Calendar = ({
  reservations,
  availability,
  portalAvailability,
  portalAvailabilityHistory,
  thisMonth,
  setMonth,
  roomId,
}: {
  reservations: any[];
  availability: any;
  portalAvailability: number;
  portalAvailabilityHistory: number;
  thisMonth: string;
  setMonth: any;
  roomId: string;
}) => {
  //   const [thisMonth, setMonth] = useState(dayjs().format("YYYY-MM-DD"));

  const finalMonth = dayjs()
    .add(portalAvailability, "month")
    .format("YYYY-MM-DD");
  const firstMonth = dayjs()
    .subtract(portalAvailabilityHistory, "month")
    .format("YYYY-MM-DD");

  const handleLastMonth = () => {
    setMonth(dayjs(thisMonth).subtract(1, "month").format("YYYY-MM-DD"));
  };

  const handleNextMonth = () => {
    setMonth(dayjs(thisMonth).add(1, "month").format("YYYY-MM-DD"));
  };

  const daysToAddStart = +dayjs(thisMonth).startOf("month").format("d") - 1;
  const daysToAddEnd = 7 - +dayjs(thisMonth).endOf("month").format("d");

  const firstDay = dayjs(thisMonth)
    .startOf("month")
    .subtract(daysToAddStart, "days")
    .format("YYYY-MM-DD");
  const lastDay = dayjs(thisMonth)
    .endOf("month")
    .add(daysToAddEnd, "days")
    .format("YYYY-MM-DD");
  const length = dayjs(lastDay).diff(firstDay, "days");

  const days = Array(length + 1)
    .fill("d")
    .map((_, index) => {
      const date = dayjs(firstDay).add(index, "days").format("YYYY-MM-DD");

      return {
        date,
      };
    });

  //   const reservations = [
  //     {
  //       id: "AA",
  //       checkIn: "2022-03-01",
  //       checkOut: "2022-03-05",
  //       name: "Sam Steel",
  //       isOwner: true,
  //     },
  //     {
  //       id: "BB",
  //       checkIn: "2022-03-05",
  //       checkOut: "2022-03-10",
  //       name: "Roberta Steel",
  //     },
  //     {
  //       id: "Ja",
  //       checkIn: "2022-03-25",
  //       checkOut: "2022-04-05",
  //       name: "Roberta Steel",
  //     },
  //   ];

  const daysInRes = reservations.reduce((sum: any, res: any) => {
    const { id, checkIn, checkOut, lastName, agent } = res;
    const isOwner = agent === "OWNER";
    const los = dayjs(checkOut).diff(checkIn, "days");
    Array(los)
      .fill("d")
      .forEach((_, index) => {
        const date = dayjs(checkIn).add(index, "days").format("YYYY-MM-DD");
        const isFirstDay = index === 0;
        const isLastDay = index === los - 1;
        sum[date] = {
          reservationId: id,
          name: lastName,
          isFirstDay,
          isLastDay,
          isOwner,
        };
      });

    return sum;
  }, {});

  return (
    <div className="text-center">
      <div className="flex items-center text-gray-900">
        {dayjs(thisMonth).format("YYYY-MM") >
          dayjs(firstMonth).format("YYYY-MM") && (
          <button
            type="button"
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
            onClick={() => handleLastMonth()}
          >
            <span className="sr-only">Previous month</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        )}

        <div className="flex-auto font-semibold">
          {dayjs(thisMonth).format("MMMM YYYY")}
        </div>
        {dayjs(thisMonth).format("YYYY-MM") <
          dayjs(finalMonth).format("YYYY-MM") && (
          <button
            type="button"
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
            onClick={() => handleNextMonth()}
          >
            <span className="sr-only">Next month</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        )}
      </div>
      <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
        <div>M</div>
        <div>T</div>
        <div>W</div>
        <div>T</div>
        <div>F</div>
        <div>S</div>
        <div>S</div>
      </div>
      <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
        {days.map((day: any, dayIdx: number) => {
          const { date } = day;
          const isCurrentMonth =
            dayjs(thisMonth).format("YYYY-MM") ===
            dayjs(date).format("YYYY-MM");

          const isToday =
            dayjs().format("YYYY-MM-DD") === dayjs(date).format("YYYY-MM-DD");

          const isPast = date < dayjs().format("YYYY-MM-DD");

          const isSelected = false;

          const { reservationId, isFirstDay, isOwner } =
            daysInRes?.[date] || {};

          const isOccupied =
            availability?.[date]?.[roomId]?.isAvailable === false;

          return (
            <button
              key={date}
              type="button"
              className={classNames(
                "py-1.5 focus:z-10",
                isPast && "text-red-400",
                isCurrentMonth ? "bg-white" : "bg-gray-50",
                (isSelected || isToday) && "font-semibold",
                isSelected && "text-white",
                !isSelected && isCurrentMonth && !isToday && "text-gray-900",
                !isSelected && !isCurrentMonth && !isToday && "text-gray-400",
                isToday && !isSelected && "text-indigo-600",
                dayIdx === 0 && "rounded-tl-lg",
                dayIdx === 6 && "rounded-tr-lg",
                dayIdx === days.length - 7 && "rounded-bl-lg",
                dayIdx === days.length - 1 && "rounded-br-lg",
                !!isOccupied && "bg-red-100"
              )}
            >
              <time
                dateTime={day.date}
                className={classNames(
                  "mx-auto flex h-7 w-7 items-center justify-center rounded-full text-xs",
                  isSelected && isToday && "bg-indigo-600",
                  isSelected && !isToday && "bg-gray-900"
                )}
              >
                {dayjs(date).format("DD")}
              </time>

              <div className="bg-transparent h-2 text-sm">
                {!!reservationId && (
                  <>
                    {!!isFirstDay ? (
                      <div
                        className={classNames(
                          "h-full w-2/3 ml-auto",
                          isOwner ? "bg-green-600" : "bg-indigo-600"
                        )}
                      />
                    ) : (
                      <div
                        className={classNames(
                          "h-full",
                          isOwner ? "bg-green-600" : "bg-indigo-600"
                        )}
                      />
                    )}
                  </>
                )}
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};
