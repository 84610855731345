import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link, useLocation } from "react-router-dom";
import { signOut } from "../action/actionAuth";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Header({
  url,
  navigation,
  tabId,
  account,
  property,
  reservation,
  staff,
}: {
  url: string;
  navigation: any[];
  tabId: string;
  account?: any;
  reservation?: any;
  staff?: any;
  property: any;
}) {
  const label =
    staff?.lastName ||
    staff?.firstName ||
    account?.label ||
    reservation?.lastName ||
    "";

  const { portfolioLogo: logoFromReservation } = reservation || {};
  const { portfolioLogo: logoFromOwner } = account || {};
  const { name: propertyName, avatar, websiteLink } = property || {};

  const firstInit = String(label)?.[0] || "";
  const location = useLocation();
  const handleSignOut = () => {
    signOut();
    window.location.replace(location.pathname + location.search);
  };

  const displayedLogo = logoFromReservation || logoFromOwner || avatar;
  return (
    <Disclosure as="nav" className="bg-white border-b border-gray-200">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                  <a href={websiteLink} target="_blank" rel="noreferrer">
                    <img
                      className="block lg:hidden h-14 w-auto"
                      src={displayedLogo}
                      alt={propertyName}
                    />
                    <img
                      className="hidden lg:block h-14 w-auto"
                      src={displayedLogo}
                      alt={propertyName}
                    />
                  </a>
                </div>
                <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                  {navigation.map((item) => {
                    if (!item) {
                      return null;
                    }
                    return (
                      <Link
                        key={item.name}
                        to={`${url}${item.href}` + location.search}
                        className={classNames(
                          tabId === item.href
                            ? "border-indigo-500 text-gray-900"
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                          "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                        )}
                        aria-current={tabId === item.href ? "page" : undefined}
                      >
                        {item.name}
                      </Link>
                    );
                  })}
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="max-w-xs bg-white flex items-center text-sm focus:outline-none px-2">
                      <span className="sr-only">Open user menu</span>
                      <div className="bg-gray-500 flex-shrink-0 flex items-center justify-center h-8 w-8 rounded-full text-white text-sm font-medium">
                        {firstInit}
                      </div>
                      <div className="ml-2">{label}</div>
                      {/* <img
                        className="h-8 w-8 rounded-full"
                        src={user.imageUrl}
                        alt=""
                      /> */}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                            )}
                            onClick={handleSignOut}
                          >
                            Sign Out
                          </div>
                        )}
                      </Menu.Item>

                      {/* {userNavigation.map((item) => {
                        return (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <Link
                                to={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                {item.name}
                              </Link>
                            )}
                          </Menu.Item>
                        );
                      })} */}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={`${url}${item.href}`}
                  className={classNames(
                    item.current
                      ? "bg-indigo-50 border-indigo-500 text-indigo-700"
                      : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800",
                    "block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="flex items-center px-4">
                <div className="flex-between">
                  <div className="bg-gray-500 flex-shrink-0 flex items-center justify-center h-8 w-8 rounded-full text-white text-sm font-medium">
                    {firstInit}
                  </div>
                </div>

                {/* <div className="flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={user.imageUrl}
                    alt=""
                  />
                </div> */}
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    {label}
                  </div>
                  {/* <div className="text-sm font-medium text-gray-500">
                    {email}
                  </div> */}
                </div>
              </div>
              <div className="mt-3 space-y-1">
                <div
                  onClick={handleSignOut}
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                >
                  Sign Out
                </div>
                {/* {userNavigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                  >
                    {item.name}
                  </a>
                ))} */}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
